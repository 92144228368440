import { api } from '@iso/config/site.config';
import { clearUserData } from '@iso/lib/helpers/utility';

class JwtAuth {
  login = async userInfo => {
    let loginUrl = 'auth/loginCode';
    if (!userInfo) {
      return { error: 'Por favor ingrese con el enlace proporcionado' };
    }
    if (!userInfo.ci && (userInfo.email === undefined && userInfo.password === undefined)) {
      return { error: 'Por favor complete la entrada' };
    }
    if ((!userInfo.email || !userInfo.password) && userInfo.ci === undefined) {
      return { error: 'Por favor complete la entrada' };
    }
    if (userInfo.email !== undefined && userInfo.password !== undefined) {
      loginUrl = 'auth/login';
    }

    return await api.post(loginUrl, userInfo)
      .then(response => response.data)
      .then(res => {
        const result = {};
        if (res.token) {
          result.token = res.token;
          result.success = res.success;
          result.userName = res.user_name
          result.profilePicture = res.profile_picture
          result.lessons = res.lessons
          return result;
        } else {
          return res;
        }
      })
      .catch(error => ({ error }));
  };

  logout = async () => {
    return await api.post('auth/logout', {})
      .then(response => response.data)
      .then(res => {
        clearUserData();
      })
      .catch(error => ( console.log(error) ));
  }
}

export default new JwtAuth();
